/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import terms from 'assets/terms'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { TextInput, Loader } from 'components'
import { shouldBreakWord, useMountEffect } from 'utils'
import { Collection } from 'types'
import { CollectionSignal, getCollections, getNextCollections } from 'services/collections'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import CollectionPanel from 'pages/home/panels/collection/CollectionPanel'

import './CollectionTable.scss'

export default function CollectionTable() {
  useMountEffect(getCollections)
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')
  const collections = CollectionSignal.lists.value
  const isLoading = CollectionSignal.loading.value

  const handleSearch = useCallback(debounce((value: string) => {
    setSearch(value)
    getCollections(value)
  }, 500), [])

  const handleDisplay = (collection: Collection) => () => {
    PanelSignal.value = <CollectionPanel collection={collection} />
  }

  useEffect(() => {
    const handleScroll = () => {
      if (tableWrapperRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = tableWrapperRef.current
        if (scrollTop + clientHeight >= scrollHeight - 100 && !CollectionSignal.loading.value) {
          getNextCollections()
        }
      }
    }

    tableWrapperRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      tableWrapperRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [tableWrapperRef.current])

  return (
    <div className="collections">
      <TextInput
        label={`${terms.Common.search} une IS`}
        onChange={handleSearch}
        bindedValue={search}
        LeftIcon={<SearchIcon />}
        RightIcon={search && isLoading && <Loader variant="x-small" />}
      />
      <div className="table-wrapper hide-scroll" ref={tableWrapperRef}>
        <table>
          <thead>
            <tr>
              <th><div className="label">Nom collection</div></th>
              <th><div className="code">Code Géo</div></th>
              <th><div className="type">Nom des IS</div></th>
              <th><div className="ferroloc">Ferrolocalisation</div></th>
            </tr>
          </thead>
          <tbody>
            {collections?.map(collection => (
              <tr className="item" key={collection.id} onClick={handleDisplay(collection)}>
                <td>
                  <div className={`label${shouldBreakWord(collection.nom_collection_gep) ? ' break-word' : ''}`}>
                    {collection.nom_collection_gep}
                  </div>
                </td>
                <td>
                  <div className="code">
                    <span>{collection.code_geographique}</span>
                  </div>
                </td>
                <td>
                  <div className="type">
                    {collection.infos_is.map(is => (
                      <span key={is.id}>{is.libelle_long}</span>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="ferroloc">
                    {collection.infos_is.map(is => (
                      <span key={is.id}>{is.ligne_id}</span>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
            {isLoading && [...Array(15)].map((_, i) => (
              <tr key={`skeleton${i}`} className="skeleton">
                <td><div className="label" /></td>
                <td><div className="code" /></td>
                <td><div className="type" /></td>
                <td><div className="ferroloc" /></td>
              </tr>
            ))}
          </tbody>
        </table>
        {collections?.length === 0 && !isLoading && (
          <h3>Aucune collection trouvée</h3>
        )}
      </div>
    </div>
  )
}
