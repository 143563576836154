import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useMapURL } from 'utils'
import RectangleLayer from './generic/RectangleLayer'

export default function CSSLayer({
  mapRef, visibility, view,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.css,
    view,
    CHARTIS_SOURCES_IDS.css,
    mapRef,
    perimeterParam,
  )

  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <RectangleLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.css}
      layers={[MAPBOX_LAYER_IDS.cssRect, MAPBOX_LAYER_IDS.cssCircle]}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      color="#be0096"
      strokeColor="#26001e"
      textField={[
        'format',
        'CSS',
        {
          'font-scale': 1.2,
        },
        '\n',
        {},
        ['get', 'libelle'],
        '\n',
        {},
        'km ',
        ['get', 'lrs_pk'],
      ]}
    />
  )
}
