import { Layer, Source } from 'react-map-gl'
import { MapDataSignal, CHARTIS_SOURCES_IDS, isLayerSelected, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useMapURL } from 'utils'

export const getChantierFilter = (): mapboxgl.Expression => {
  const isYearValid: mapboxgl.Expression = [
    'any',
    ['match',
      ['get', 'annee'],
      2024, isLayerSelected('chantier-2024'),
      2025, isLayerSelected('chantier-2025'),
      2026, isLayerSelected('chantier-2026'),
      2027, isLayerSelected('chantier-2027'),
      2028, isLayerSelected('chantier-2028'),
      2029, isLayerSelected('chantier-2029'),
      false,
    ],
    ['all', ['>=', ['get', 'annee'], 2030], isLayerSelected('chantier-2030-plus')],
  ]

  const isPhaseValid: mapboxgl.Expression = [
    'case',
    ['==', ['get', 'phase'], 'REA'], isLayerSelected('chantier-phase-rea'),
    ['==', ['get', 'phase'], 'TVX'], isLayerSelected('chantier-phase-tvx'),
    ['==', ['get', 'phase'], 'TVXP'], isLayerSelected('chantier-phase-tvxp'),
    ['==', ['get', 'phase'], 'REA_TVXP'], isLayerSelected('chantier-phase-rea-tvxp'),
    ['==', ['get', 'phase'], 'REA_TVX'], isLayerSelected('chantier-phase-rea-tvx'),
    ['==', ['get', 'phase'], 'APO_REA'], isLayerSelected('chantier-phase-apo-rea'),
    ['==', ['get', 'phase'], 'APO_TVX'], isLayerSelected('chantier-phase-apo-tvx'),
    ['==', ['get', 'phase'], 'APO_REA_TVX'], isLayerSelected('chantier-phase-apo-rea-tvx'),
    ['==', ['get', 'phase'], 'PRO_REA'], isLayerSelected('chantier-phase-pro-rea'),
    false,
  ]

  const filterASTRE = isLayerSelected('chantier-astre')
  const filterSEISM = isLayerSelected('chantier-seism')
  const filtreASTREandSEISM = isLayerSelected('chanter-astre-and-seism')

  return [
    'all',
    isYearValid,
    isPhaseValid,
    ...(filterASTRE || filtreASTREandSEISM ? [['!=', ['get', 'numero_astre'], null]] : []),
    ...(filterSEISM || filtreASTREandSEISM ? [['!=', ['get', 'seism_project_id'], null]] : []),
  ]
}

export default function ChantierLayer({ mapRef, view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.chantier,
    view,
    CHARTIS_SOURCES_IDS.chantier,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <Source
      id={CHARTIS_SOURCES_IDS.chantier}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.chantierLine}
        type="line"
        source-layer={CHARTIS_SOURCES_IDS.chantier}
        paint={{
          'line-width': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 2.5, 2],
          'line-color': hoverColor('#000000', hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'line-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, -1],
        }}
        filter={[
          ...getChantierFilter(),
          ['any', ['==', ['geometry-type'], 'LineString'], ['==', ['geometry-type'], 'MultiLineString']],
        ]}
      />
      <Layer
        id={MAPBOX_LAYER_IDS.chantierPoint}
        type="circle"
        source-layer={CHARTIS_SOURCES_IDS.chantier}
        paint={{
          'circle-radius': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 7, 5],
          'circle-color': hoverColor('#000000', hoveredObjectsIds),
          'circle-stroke-width': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1, 0.5],
          'circle-stroke-color': hoverColor('#ffffff', hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter.value, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
          'circle-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, -1],
        }}
        filter={[
          ...getChantierFilter(),
          ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']],
        ]}
        minzoom={9}
      />
    </Source>
  )
}
