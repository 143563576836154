/* eslint-disable camelcase */
import { signal } from '@preact/signals-react'
import { get, post } from '@osrdata/app_core/dist/requests'
import { Collection, ObjectCollection, PaginatedResponse } from 'types'
import { catchable } from 'utils'

export const CollectionSignal = {
  lists: signal<Collection[]>([]),
  loading: signal<boolean>(false),
  next: null,
}

export const ObjectCollectionSignal = {
  loading: signal<boolean>(false),
  postes: signal<{id: string, label: string}[]>([]),
  collections: signal<Collection[]>([]),
}

export const getCollection = async (id: string) => {
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(() => get<Collection>(`/dexcarto/ouranos/collections/${id}/`))
  return !error ? response : null
}

export const getCollections = async (search?: string) => {
  CollectionSignal.lists.value = []
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(
    () => get<PaginatedResponse<Collection>>('/dexcarto/ouranos/collections/', search && {
      search,
    }),
  )
  if (!error) {
    CollectionSignal.lists.value = response.results
    CollectionSignal.next = response.next
  }
  CollectionSignal.loading.value = false
}

export const getNextCollections = async () => {
  if (!CollectionSignal.next) return
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(() => get<PaginatedResponse<Collection>>(CollectionSignal.next))
  if (!error) {
    CollectionSignal.lists.value = [...CollectionSignal.lists.value, ...response.results]
    CollectionSignal.next = response.next
  }
  CollectionSignal.loading.value = false
}

export const getObjectCollections = async (layer_slug: string, object_id: string) => {
  ObjectCollectionSignal.loading.value = true
  ObjectCollectionSignal.postes.value = []
  ObjectCollectionSignal.collections.value = []
  const [error, response] = await catchable(() => post<ObjectCollection[]>(
    '/dexcarto/ouranos/object-collections/',
    { object_id, layer_slug },
  ))
  if (!error) {
    ObjectCollectionSignal.postes.value = response.flatMap(r => r.postes_rattachement.map(poste => ({
      id: poste.id,
      label: poste.libelle_long,
    })))
    ObjectCollectionSignal.collections.value = response.flatMap(r => r.collections)
  }
  ObjectCollectionSignal.loading.value = false
}
