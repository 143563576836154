import { Accordion } from 'components'
import { SearchResult, SearchResults as SearchResultsType } from 'types'
import { handleLocateObject, MapDataSignal, SOURCE_NAMES } from 'services'
import { Feature } from 'geojson'
import './SearchResults.scss'
import ResultTable from '../../common/ResultTable'
import { PanelSignal } from '../../PanelsManager'
import InfoSinglePanel from '../../info/InfoSinglePanel'
import SearchPanel from '../SearchPanel'

interface Props {
  results: SearchResultsType
  searchInput: string
}

const getColumns = (result: SearchResult) => {
  switch (result.type) {
    case 'cassini':
      return {
        libelle: 'Libellé',
        lrs_ligne: 'Ligne',
        lrs_pk: 'PK',
      }
    case 'chantier':
      return {
        num_compte_geremi: 'C6',
        numero_astre: 'ASTRE',
        libelle: 'Libellé',
      }
    case 'cassini-type':
      return {
        libelle: 'Libellé',
        type_installation_fixe_id_mnemo: 'Type',
        lrs_ligne: 'Ligne',
        lrs_pk: 'PK',
      }
    case 'zone':
      return {
        libelle: 'Libellé',
      }
    default:
      return {}
  }
}

const handleHoverResult = (featureId: string) => () => {
  MapDataSignal.hoveredObjects.value = [{ properties: { id: featureId } }].filter(Boolean)
}

export default function SearchResults({ results, searchInput }: Props) {
  const handleClickResult = (result: Feature) => () => {
    handleLocateObject(result.properties.id, result.properties.layer_slug)
    PanelSignal.value = (
      <InfoSinglePanel
        feature={{ properties: result.properties,
          source: result.properties.layer_slug,
          sourceLayer: result.properties.layer_slug }}
        onBack={() => { PanelSignal.value = (<SearchPanel defaultSearchValue={searchInput} />) }}
      />
    )
    MapDataSignal.targetedObject.value = { properties: { ...result.properties } }
  }

  if (!Object.keys(results).length) {
    return <div className="search-results empty">Aucun résultat</div>
  }

  return (
    <div className="search-results">
      {Object.entries(results).map(([source, objects]) => (
        <Accordion key={source} title={SOURCE_NAMES[source] ?? 'Couche inconnue'}>
          <ResultTable
            features={objects.results.features}
            columns={getColumns(objects)}
            handleClickObject={handleClickResult}
            handleHoverObject={handleHoverResult}
          />
        </Accordion>
      ))}
    </div>
  )
}
