import { MapDataSignal, CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { hoverColor, useActivePerimeterParams, useHoveredObjectsIds, useMapURL } from 'utils'
import PictoLayer from './generic/PictoLayer'

export default function StationLayer({
  mapRef, visibility, view,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat_centroid__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.station,
    view,
    CHARTIS_SOURCES_IDS.station,
    mapRef,
    perimeterParam,
  )

  const hoveredObjectsIds = useHoveredObjectsIds()
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PictoLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.station}
      layer={MAPBOX_LAYER_IDS.station}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      layout={{
        'icon-image': ['case',
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'station_red',
          'station'],
        'icon-size': 0.5,
        'icon-allow-overlap': false,
        'text-size': 14,
        'text-anchor': 'left',
        'symbol-placement': 'point',
        'text-allow-overlap': false,
        'text-offset': [1.5, 0],
        'text-field': '{libelle}',
      }}
      paint={{ 'text-color': hoverColor('#1c37fc', hoveredObjectsIds) }}
    />
  )
}
