import { MouseEvent } from 'react'
import { ReactComponent as DeleteBlueIcon } from 'assets/icons/delete-blue.svg'
import { deleteFavoriteCollection, FavoritesSignal, getCollection } from 'services'
import { ConfirmModal, ModalSignal } from 'components'
import { Collection } from 'types'
import CollectionPanel from '../collection/CollectionPanel'
import { PanelSignal } from '../PanelsManager'
import FavoritesPanel from './FavoritesPanel'

export default function FavoriteCollections() {
  const favorites = FavoritesSignal.collections.value

  const handleDelete = (id: string) => (e: MouseEvent) => {
    e.stopPropagation()
    const closeModal = () => { ModalSignal.value = undefined }
    ModalSignal.value = (
      <ConfirmModal
        title="Supprimer le favori ?"
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteFavoriteCollection(id)
          closeModal()
        }}
      />
    )
  }

  const handleDisplayCollection = (collection: Collection) => async () => {
    const fetchedCollection = await getCollection(collection.id)
    if (fetchedCollection) {
      PanelSignal.value = (
        <CollectionPanel
          collection={fetchedCollection}
          onBack={() => { PanelSignal.value = <FavoritesPanel /> }}
        />
      )
    }
  }

  return (
    <div className="objects-favorites favorites hide-scroll">
      {favorites.map(favorite => (
        <div
          key={favorite.id}
          className="favorite objects"
          onClick={handleDisplayCollection(favorite)}
        >
          <div className="flex-center head">
            <p>
              <b>{favorite.nom_collection_gep}</b>
              <br />
              <span className="info">{`Code géo : ${favorite.code_geographique}`}</span>
            </p>
            <DeleteBlueIcon onClick={handleDelete(favorite.favorite_id)} />
          </div>
        </div>
      ))}
    </div>
  )
}
