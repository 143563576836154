import { ReactComponent as SimpleIcon } from 'assets/icons/simple.svg'
import { ReactComponent as SplitIcon } from 'assets/icons/split.svg'
import terms from 'assets/terms'
import { ContentSignals, reprojectViewport } from 'services'
import { Button, ButtonSize, ButtonStyle } from 'components'
import ToolWrapper from './ToolWrapper'

import './ContentSelectionTool.scss'

type Props = {
  onClose: () => void
}

export default function ContentSelectionTool({ onClose }: Props) {
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  const handleContentSelection = (content: 'simple' | 'split') => async () => {
    if (content === 'simple') {
      leftData.value = { ...leftData.value, type: leftData.value.type || 'sch' }
      ContentSignals.right.data.value = null
    } else {
      const rightType = leftData.value?.type === 'sch' ? 'geo' : 'sch'
      const rightVp = await reprojectViewport(leftData.value?.vp, leftData.value?.type, rightType)
      rightData.value = { type: rightType, vp: rightVp }
    }
  }

  const contents = [
    {
      label: terms.ToolsPanel.content.simple,
      icon: <SimpleIcon />,
      onClick: handleContentSelection('simple'),
      enable: true,
    },
    {
      label: terms.ToolsPanel.content.split,
      icon: <SplitIcon />,
      onClick: handleContentSelection('split'),
      enable: true,
    },
  ]

  return (
    <ToolWrapper title={terms.ToolsPanel.content.title} animate>
      {contents.map(content => (
        <Button
          key={content.label}
          style={ButtonStyle.borderLess}
          text={content.label}
          size={ButtonSize.medium}
          onClick={() => {
            content.onClick()
            onClose()
          }}
          disabled={!content.enable}
          icon={content.icon}
        />
      ))}
    </ToolWrapper>
  )
}
