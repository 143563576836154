import { handleLocateObject, MapDataSignal, SOURCE_NAMES } from 'services'
import { LayerFeature } from 'types'
import { PanelSignal } from '../PanelsManager'
import InfoSinglePanel from './InfoSinglePanel'

import './InfoPanel.scss'

type Props = {
  data: LayerFeature[]
}

export default function InfoListPanel({ data }: Props) {
  const sources = data?.map(d => d.source).filter(
    (value, index, self) => self.indexOf(value) === index && Object.keys(SOURCE_NAMES).includes(value),
  )

  const handleSelect = (feature: LayerFeature) => () => {
    handleLocateObject(feature.properties.id, feature.source)
    PanelSignal.value = (
      <InfoSinglePanel
        feature={feature}
        onBack={() => { PanelSignal.value = <InfoListPanel data={data} /> }}
      />
    )
  }

  const handleHover = (features: LayerFeature[]) => () => {
    MapDataSignal.hoveredObjects.value = [...features].filter(Boolean)
  }

  return (
    <>
      <h3 className="panel-title">{`Objets superposés (${data?.length})`}</h3>
      <div className="info-panel panel">
        <div className="objects-list">
          {sources?.map(source => (
            <div key={source} className="sources">
              <p className="source">{SOURCE_NAMES[source] ?? source}</p>
              {data?.filter(d => d.source.includes(source)).map(d => (
                <div
                  key={d.properties.id}
                  className="data"
                  onClick={handleSelect(d)}
                  onMouseEnter={handleHover([d])}
                  onMouseLeave={handleHover([])}
                >
                  <p>
                    {d?.properties?.num_compte_geremi && (
                      `${d?.properties?.num_compte_geremi} - `
                    )}
                    {d?.properties?.numero_astre && (
                    <>
                      {d?.properties?.numero_astre}
                      <br />
                    </>
                    )}
                    {d.properties.libelle_long || d.properties.libelle || 'N/C'}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
