import terms from 'assets/terms'
import { Accordion, Button, ButtonStyle, Loader } from 'components'
import {
  ContentSignals, getOnScreenBAL, getOnScreenChantier, getOnScreenPoste, useTabs, MapDataSignal,
  handleLocateObject,
} from 'services'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { OnScreenResult, OnScreenResults } from 'types'
import { PanelSignal } from '../PanelsManager'
import InfoSinglePanel from '../info/InfoSinglePanel'

import './OnScreenPanel.scss'
import ResultTable from '../common/ResultTable'

const getCount = (count: number) => {
  if (!count) return ''
  if (count >= 50) return ' (50+)'
  return ` (${count})`
}

const MIN_ZOOM = 10

export default function OnScreenPanel() {
  const { possibleTabs, selectedTab, setSelectedTab } = useTabs()
  const [searchPostePending, setSearchPostePending] = useState<boolean>(true)
  const [noPosteSelected, setNoPosteSelected] = useState<boolean>(false)
  const [searchZonePending, setSearchZonePending] = useState<boolean>(true)
  const [searchTravauxPending, setSearchTravauxPending] = useState<boolean>(true)
  const [noTravauxSelected, setNoTravauxSelected] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<OnScreenResults>({})

  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  const debouncedSearch = useCallback(
    debounce((bbox, type) => {
      setSearchResults({})
      setSearchPostePending(true)
      setSearchZonePending(true)
      setSearchTravauxPending(true)
      getOnScreenPoste(bbox, type).then(res => {
        setNoPosteSelected(!res)
        if (!res) return
        setSearchResults(prev => ({ ...prev, posteObjects: res }))
      }).then(() => setSearchPostePending(false))
      getOnScreenBAL(bbox, type).then(res => {
        setSearchResults(prev => ({ ...prev, balObjects: res }))
      }).then(() => setSearchZonePending(false))
      getOnScreenChantier(bbox, type).then(res => {
        setNoTravauxSelected(!res)
        if (!res) return
        setSearchResults(prev => ({ ...prev, chantierObjects: res }))
      }).then(() => setSearchTravauxPending(false))
    }, 500),
    [],
  )

  useEffect(() => {
    if (!(selectedTab?.position === 'left')) return
    const { type, bbox } = leftData.value
    if (!bbox) return
    if (type !== 'geo' && type !== 'sch') return
    if (leftData.value?.vp.zoom < MIN_ZOOM) return
    debouncedSearch(bbox, type)
  }, [leftData.value?.vp, selectedTab?.position])

  useEffect(() => {
    if (!(selectedTab?.position === 'right')) return
    const { type, bbox } = rightData.value
    if (!bbox) return
    if (type !== 'geo' && type !== 'sch') return
    if (rightData.value?.vp.zoom < MIN_ZOOM) return
    debouncedSearch(bbox, type)
  }, [rightData.value?.vp, selectedTab?.position])

  const handleHoverObject = (id: string) => () => {
    MapDataSignal.hoveredObjects.value = [{ properties: { id } }].filter(Boolean)
  }

  const handleClickResult = (result: OnScreenResult) => () => {
    handleLocateObject(result.properties.id, result.properties.layer_slug)
    PanelSignal.value = (
      <InfoSinglePanel
        feature={{ properties: result.properties,
          source: result.properties.layer_slug,
          sourceLayer: result.properties.layer_slug }}
        onBack={() => { PanelSignal.value = <OnScreenPanel /> }}
      />
    )
    MapDataSignal.targetedObject.value = { properties: { ...result.properties } }
  }

  const renderContent = () => {
    if (selectedTab?.position === 'left' && leftData.value?.vp.zoom < MIN_ZOOM) {
      return (
        <span>Niveau de zoom trop large</span>
      )
    } if (selectedTab?.position === 'right' && rightData.value?.vp.zoom < MIN_ZOOM) {
      return (
        <span>Niveau de zoom trop large</span>
      )
    }
    return (
      <>
        <Accordion title={`Postes${getCount(searchResults?.posteObjects?.features.length)}`}>
          {searchPostePending ? (<Loader variant="small" />) : (
            <ResultTable
              features={searchResults.posteObjects?.features || []}
              columns={{
                libelle_long: 'Libellé',
                type_installation_fixe_id_mnemo: 'Type',
                lrs_ligne: 'Ligne',
                lrs_pk: 'PK',
              }}
              handleClickObject={handleClickResult}
              handleHoverObject={handleHoverObject}
              noObjectMessage={noPosteSelected ? 'Seuls les éléments des couches actuellement actives sont affichés.'
                : 'Aucun poste'}
            />
          )}
        </Accordion>
        <Accordion title={`Tronçon${getCount(searchResults?.balObjects?.features.length)}`}>
          {searchZonePending ? (<Loader variant="small" />) : (
            <ResultTable
              features={searchResults.balObjects?.features || []}
              columns={{
                lrs_ligne: 'Ligne',
                libelle: 'Libellé',
              }}
              handleClickObject={handleClickResult}
              handleHoverObject={handleHoverObject}
              noObjectMessage="Aucun tronçon"
            />
          )}
        </Accordion>
        <Accordion title={`Projets Travaux${getCount(searchResults?.chantierObjects?.features.length)}`}>
          {searchTravauxPending ? (<Loader variant="small" />) : (
            <ResultTable
              features={searchResults.chantierObjects?.features || []}
              columns={{
                num_compte_geremi: 'C6',
                numero_astre: 'ASTRE',
                libelle: 'Libellé',
              }}
              handleClickObject={handleClickResult}
              handleHoverObject={handleHoverObject}
              noObjectMessage={noTravauxSelected ? 'Seuls les éléments des couches actuellement actives sont affichés.'
                : 'Aucun projet'}
            />
          )}
        </Accordion>
      </>
    )
  }

  return (
    <>
      <h3 className="panel-title">{terms.Header.toScreen}</h3>
      <div className="on-screen-panel">
        {rightData.value?.type && (
        <div className="tabs flex-center">
          {possibleTabs.length ? possibleTabs.map(tab => (
            <Button
              key={tab.position}
              className={selectedTab?.position === tab.position ? 'selected' : ''}
              text={terms.Panels.Search.tabs[tab.position]}
              style={ButtonStyle.borderLess}
              onClick={() => setSelectedTab(tab)}
            />
          )) : <span className="no-tab">{terms.Panels.Search.noTab}</span>}
        </div>
        )}
        {renderContent()}
      </div>
    </>
  )
}
